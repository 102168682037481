var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-widget",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "header-actions": _vm.headerActions,
            title: _vm.meta.title,
            "sub-title": _vm.meta.subtitle,
            "toolbar-actions": _vm.toolbarActions,
          },
          on: {
            "toolbar-action": function ($event) {
              return _vm.toolbarMethod($event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "titleIcon",
              fn: function () {
                return [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        content: _vm.totalRecords,
                        color: "error",
                        overlap: "",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: _vm.appConfig.window.toolbarActions.color,
                          },
                        },
                        [_vm._v("mdi-bell-outline")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "asyent-widget",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("asyent-search", {
        attrs: {
          "filter-dialog": _vm.filterDialog,
          filters: _vm.filters,
          headers: _vm.headers,
          "reload-counter": _vm.reloadCounter,
          "row-actions": _vm.rowActions,
          "row-method": _vm.rowMethod,
          "search-method": _vm.search,
          criteria: _vm.criteria,
          "no-height": _vm.widget,
          "export-counter": _vm.exportCounter,
          "export-file-name": "tasks-accounts",
          "no-outline": _vm.widget,
          "height-limit": "",
          "items-per-page-options": [5],
          "simple-loading": "",
        },
        on: {
          "filter-dialog": function ($event) {
            _vm.filterDialog = $event
          },
          "total-records": function ($event) {
            _vm.totalRecords = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.carrierBookingNumber",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("div", { staticClass: "urgent" }, [
                  _c("span", [_vm._v(_vm._s(item.carrierBookingNumber))]),
                  _vm.getUrgentClass(item)
                    ? _c("span", { staticClass: "icon" }, [_vm._v("🔴")])
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "item.arrivalStatus",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      label: "",
                      color: _vm.arrivalStatusColor(item.arrivalStatus),
                      small: "",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.ae$lng(
                              item.arrivalStatus
                                ? item.arrivalStatus
                                : "NOT_ARRIVED"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.customsAction",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(item.customsAction ? "Running" : "Expired") +
                      " "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }