var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "page-path": _vm.ae$pagePath,
        title: _vm.ae$title,
        "title-icon": "mdi-view-dashboard",
        "title-icon-color": "red",
        "initial-width": 4,
      },
    },
    [
      _c("div", { staticClass: "dashboard" }, [
        _vm.appConfig.dashboard.userAccounts.enable &&
        _vm.sso.hasRoles("connectid-account-review")
          ? _c(
              "div",
              { staticClass: "dashboard-item" },
              [_c("AccountTasks")],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "dashboard-item" },
          [
            _c(
              "asyent-dashboard-panel",
              {
                attrs: {
                  icon: "mdi-postage-stamp",
                  "avatar-color": "red",
                  title: "Consignments",
                },
              },
              [
                _c("TasksPostal", {
                  attrs: {
                    color: "blue darken-3\n",
                    light: "",
                    "toc-level": 2,
                    "with-border": "",
                    expandable: "",
                    "no-maximize": "",
                    widget: "",
                    meta: {
                      title: "Posted Today",
                      subtitle: "Consignments",
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "dashboard-item" },
          [
            _c(
              "asyent-dashboard-panel",
              {
                attrs: {
                  icon: "mdi-postage-stamp",
                  "avatar-color": "red",
                  title: "Arrived Consignments",
                },
              },
              [
                _c("TasksPostal", {
                  attrs: {
                    color: "blue darken-3",
                    light: "",
                    "toc-level": 2,
                    "with-border": "",
                    expandable: "",
                    "no-maximize": "",
                    widget: "",
                    meta: {
                      title: "Arrived",
                      subtitle: "Consignments",
                    },
                    "criteria-prop": [
                      {
                        operation: "EQ",
                        dataType: "TEXT",
                        fieldName: "providerName",
                        values: ["UPU"],
                      },
                      {
                        operation: "EQ",
                        dataType: "ENUMERATION",
                        fieldName: "arrivalStatus",
                        values: ["ARRIVED"],
                      },
                    ],
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }